<template>
  <div class="container content">
    <div v-html="contents.page.mainContent" class="mb-5"></div>
    <div class="table-responsive">
      <table>
        <thead>
         <tr>
           <th></th>
           <th>{{contents.participants.col1}}</th>
           <th>{{contents.participants.col2}}</th>
         </tr>
        </thead>
        <tbody>
          <tr v-for="participant in participants" :key="participant.key">
            <td><div>{{participant.place}}</div></td>
            <td>{{participant.teamName}}</td>
            <td>{{participant.university}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>

export default{
  name:'Participants',
  props:['globalData', 'pageId'],
  data(){
    return{

    }
  },  
  computed:{
    userLanguage(){
      return this.globalData.init.language
    },
    contents(){
      let language = this.userLanguage
      let contents = this.globalData.contents[language][this.pageId]

      this.globalData.init.head.pageId = this.pageId
      this.globalData.init.head.title = contents.head.title
      this.globalData.init.head.keywords = contents.head.keywords
      this.globalData.init.head.description = contents.head.description

      return contents
    },
    participants(){
        let participants = this.globalData.init.participants;
        participants.map( (participant, index) => {
          const newPropsObj = {
            'place':index + 1
          };
          return Object.assign(participants[index], newPropsObj);
        })
        console.log(participants)
        return participants
    }
  }
}
</script>

<style scoped>
table{
  font-family: 'MS_Gloriola_II_Std_Semibd',sans-serif;
}
table th, .subpage table td{
  border-color: #E5E5E5;
  border-bottom-width: 1px;
}
table th{
  font-family: 'Karla',sans-serif;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color:#666666;
}
table th:first-child{
  border-bottom: none;
}
table tr td{
  vertical-align: middle;
}
table tr td:first-child{
  height: 96px;
  width: 128px;
  border-bottom: none;
}
table tr div{
  height: 80px;
  width: 80px;
  line-height: 80px;
  background-color: #187ABA;
  border-radius: 50%;
  font-size: 20px;
  text-align: center;
  color: #FFFFFF;
}
table tr td:nth-child(2){
  font-size: 20px;
  line-height: 24px;
  min-width: 31rem;
}
table tr td:nth-child(3){
  color:#002B51;
}
table tr td:nth-child(4){
  font-size: 30px;
  line-height: 40px;
  color:#672CB4;
  width:5rem;
}
</style>